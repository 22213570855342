export const featuredFilters = ['Crowd pleasers', 'hungerhub exclusives', 'New'];
export const cuisineFilters = [
  'Healthy',
  'Breakfast',
  'Sandwiches & Burgers',
  'Thai',
  'Sushi',
  'Bowls',
  'Mexican & Latin American',
  'Greek & Middle Eastern',
  'Pizza',
  'Poke & Seafood',
  'Indian',
  'Asian',
  'Cafe',
  'European',
  'Japanese',
  'Italian',
  'Chicken',
  'African',
  'Carribean',
  'Comfort Food',
];

export const cuisineFiltersForModal = [
  'Healthy',
  'Asian',
  'Breakfast',
  'Cafe',
  'Sandwiches & Burgers',
  'Indian',
  'Thai',
  'European',
  'Sushi',
  'Japanese',
  'Bowls',
  'Italian',
  'Mexican & Latin American',
  'Chicken',
  'Greek & Middle Eastern',
  'African',
  'Pizza',
  'Caribbean',
  'Poke & Seafood',
  'Comfort Food',
];

export const dietaryFilters = ['Vegan', 'Halal', 'Vegetarian', 'Gluten Free', 'Nut & Peanut free'];

export const packagingFilters = [
  'Individual Meals',
  'Eco-friendly',
  'Platters & Trays',
  'Pre-set meals',
];

export const sortByOptions = [
  { label: 'Sort by Distance', value: '' },
  { label: 'Sort by Newest', value: 'newest' },
  { label: 'Featured', value: 'featured' },
  { label: 'Alphabetical', value: 'name' },
];

import dynamic from 'next/dynamic';
import { sortByOptions } from 'utils/constants/filters';
import { customStyles } from 'utils/helpers/styles';
const Select = dynamic(() => import('react-select'), { ssr: false });

const SortBySelect = ({ sortBy, onChange }) => (
  <Select
    styles={customStyles}
    aria-labelledby="sort-by"
    aria-label="sort-by"
    placeholder="Sort by"
    closeMenuOnSelect
    value={sortBy}
    onChange={onChange}
    options={sortByOptions}
  />
);

export default SortBySelect;

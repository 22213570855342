import React, { Fragment, useContext, useEffect, useState } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition } from '@headlessui/react';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { checkIsDateAvailable } from 'utils/helpers/dateTime';
import { useRouter } from 'next/router';
import moment from 'moment-timezone';
import QueryString from 'qs';
import GoogleAutoComplete from 'components/GoogleAutoComplete';

const Error = ({ message = 'This field needs to be filled' }) => (
  <span className="text-attention text-xs mt-1 animate-fade-in-down">{message}</span>
);

function AddressTimeModal({
  visible,
  setModal,
  setAddressValidationAlert,
  getAvailableTimes,
  getAvailableDates,
  isGoBack,
  setDateValidationAlert,
  restaurantDetail,
  setApplied,
  isCart,
}) {
  const screenType = Screen.ScreenType();
  const [internalAddress, setAddress] = useState(null);
  const [isAdressValid, setIsValidAddress] = useState(true);
  const [addressError, setAddressError] = useState(false);
  const [completeAddressError, setCompleteAddressError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const restaurantStore = toJS(store?.restaurantStore);
  const { address, completeAddress, selectedDate, selectedTime, availableDates } = userStore;
  const { activeRestaurant } = restaurantStore;

  const router = useRouter();

  let date = selectedDate;
  let time = selectedTime;

  useEffect(() => {
    if (date && dateError) setDateError(false);

    if (time && timeError) setTimeError(false);
  }, [date, time]);

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    if (visible) {
      setAddress({ address, completeAddress });

      if (date && moment(date).isBefore(moment())) {
        store.userStore.setDate(null);
        store.userStore.setTime(null);
      }
    }
  }, [visible, address]);

  const apply = async () => {
    if (!internalAddress?.address) {
      setAddressError(true);
    } else if (!internalAddress?.completeAddress?.city) {
      setCompleteAddressError(true);
    } else if (!date) {
      setDateError(true);
    } else if (!time) {
      setTimeError(true);
    } else {
      store?.userStore?.setAddress(internalAddress?.address, internalAddress?.completeAddress);

      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            date: moment(date).format('YYYY-MM-DD'),
            time,
            address: internalAddress?.address,
          },
        },
        undefined,
        {
          shallow: true,
        }
      );

      if (isCart) {
        const params = {
          restaurant_id: activeRestaurant?.attributes?.restaurant_id,
          order_address: internalAddress?.address,
        };

        if (!checkIsDateAvailable(restaurantDetail, date, time) && !isGoBack) {
          closeModal();
          setTimeout(() => setDateValidationAlert(true), 500);
          return;
        }

        store.userStore.setLoader(true);
        const response = await store?.restaurantStore?.validateAddress(params);
        store.userStore.setLoader(false);

        if (!response?.valid) {
          setAddressValidationAlert(true);

          store?.userStore?.setAddress('');
          return;
        } else {
          if (isGoBack) {
            goBack();
          }
          closeModal();
        }
      } else {
        if (isGoBack) {
          goBack();
        }

        closeModal();
        setApplied(true);
      }
    }
  };

  const goBack = () => {
    let backQuery = QueryString.parse(router?.query);
    delete backQuery['id'];
    delete backQuery['name'];
    delete backQuery['utm_source'];

    router.replace({
      pathname: '/',
      query: {
        ...backQuery,
        date: moment(date).format('YYYY-MM-DD'),
        time,
      },
    });
  };

  const isValidInfo = () => {
    return isAdressValid && date && time;
  };

  const handleOnChange = e => {
    if (addressError) setAddressError(false);
    if (completeAddressError) setCompleteAddressError(false);

    setAddress({ address: e?.target?.value });
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full bg-white md:bg-transparent md:items-center md:justify-center md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full h-full sm:h-auto md:w-6/12 max-w-[667px] transform overflow-visible sm:rounded-2xl md:rounded-2xl bg-white py-10 px-8 pt-14 text-left align-middle md:shadow-xl transition-all">
                <div className="flex justify-between items-center">
                  <Dialog.Title
                    as="h1"
                    style={{
                      fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                    }}
                    className="font-inter-semibold leading-8 text-black-light">
                    Where Would You Like Your Food Delivered?
                  </Dialog.Title>
                </div>

                <div>
                  <div className="flex flex-col mt-5">
                    <span className="text-md mb-1">Delivery address</span>

                    <div className="flex w-full md:mr-2 h-12">
                      <GoogleAutoComplete
                        className={classNames(
                          'input-light-1 outline-none focus:outline-none address-inputs font-inter-medium my-0 rounded-lg text-md text-primary-black w-full bg-background pl-4',
                          {
                            'border-attention border': addressError,
                          }
                        )}
                        onChange={handleOnChange}
                        onBlur={e => {
                          const queryDate = router?.query?.date;

                          store.userStore.getAvailableDates({
                            address: e?.target?.value,
                            month: queryDate ? moment(queryDate).month() + 1 : moment().month() + 1,
                            year: queryDate ? moment(queryDate).year() : moment().year(),
                            current_date_time: moment().format('YYYY-MM-DD HH:mm'),
                          });
                        }}
                        value={internalAddress?.address}
                        onPlaceSelected={place => {
                          const _address = place?.address_components?.reduce(
                            (seed, { long_name, types }) => (
                              types.forEach(t => (seed[t] = long_name)), seed
                            ),
                            {}
                          );

                          store.userStore?.getAndSetTimezone(place?.formatted_address);

                          setAddress({
                            address: place?.formatted_address,
                            completeAddress: {
                              suite: _address?.subpremise,
                              street_number: _address?.street_number,
                              street_name: _address?.route,
                              city: _address?.locality,
                              province: _address?.administrative_area_level_1,
                              postal_code: _address?.postal_code,
                            },
                          });
                        }}
                      />
                    </div>

                    {addressError && <Error />}

                    {completeAddressError && (
                      <Error message="Please select address from the address dropdown" />
                    )}
                  </div>

                  <div className="flex flex-col md:flex-row justify-between mt-5 overflow-scroll md:overflow-visible pb-20">
                    <div className="flex flex-col w-full md:w-1/2">
                      <span className="text-md mb-1">Delivery date</span>

                      <DatePicker
                        fromModal
                        isCart={false}
                        internalAddress={internalAddress?.address}
                        selectedDate={date}
                        activeRestaurant={activeRestaurant}
                        getAvailableTimes={getAvailableTimes}
                        getAvailableDates={getAvailableDates}
                        availableDates={availableDates}
                        minDate={new Date()}
                        format={'MMMM d, yyyy'}
                        className="flex h-12 bg-background justify-between rounded-lg pr-2 items-center"
                      />

                      {dateError && <Error />}
                    </div>

                    <div className="flex flex-col w-full md:w-1/2 mt-3 md:mt-0 md:ml-3">
                      <span className="text-md mb-1">Time</span>

                      <TimePicker
                        fromModal
                        labelName="address-time-modal"
                        isCart={false}
                        selectedDate={date}
                        selectedTime={time}
                        className="flex h-12 bg-background justify-between rounded-lg text-sm border-0 text-primary-black"
                      />

                      {timeError && <Error />}
                    </div>
                  </div>
                </div>

                <div className="hidden md:flex justify-center items-center mt-6">
                  <div className="flex">
                    <button
                      className={classNames('btn-purple py-3 text-sm px-6 ml-3', {
                        'text-secondary-gray hover:text-secondary-gray': !isValidInfo(),
                      })}
                      onClick={apply}>
                      Apply
                    </button>
                  </div>
                </div>

                <div className="md:hidden">
                  <div className="bg-white w-full py-5 justify-center items-center mt-16 border-t-2 border-gray-200">
                    <div className="flex px-5 justify-evenly">
                      <button
                        className={classNames('btn-purple w-full py-3 px-6 ml-3', {
                          'text-secondary-gray hover:text-secondary-gray': !isValidInfo(),
                        })}
                        // disabled={!isValidInfo()}
                        onClick={apply}>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default observer(AddressTimeModal);

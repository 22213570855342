import dynamic from 'next/dynamic';
const Tooltip = dynamic(() => import('components/Tooltip'));

const ExpandRadiusCheckbox = ({ setExpandedRadius, isExpandedRadius }) => (
  <label className="flex select-none cursor-pointer bg-background rounded-lg items-center h-12 px-4 md:mr-2">
    <input
      className="form-checkbox"
      type={'checkbox'}
      checked={isExpandedRadius}
      onChange={() => setExpandedRadius(!isExpandedRadius)}
    />

    <span className="ml-2 font-inter-regular text-sm mr-1">
      Expand your delivery radius to 50 km
    </span>

    <Tooltip
      grayIcon
      tooltipText={
        'Additional delivery charges may be<br/>applied based on your expanded delivery<br/>radius'
      }
      id="res-info-tooltip"
      backgroundColor={'#ffffff'}
      textColor="black"
      place={'top'}
    />
  </label>
);

export default ExpandRadiusCheckbox;

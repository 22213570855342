import Autocomplete from 'react-google-autocomplete';

const GoogleAutoComplete = ({
  value,
  onBlur,
  onChange,
  className,
  onPlaceSelected,
  placeholder = 'Type your address',
}) => (
  <Autocomplete
    options={{
      componentRestrictions: { country: 'CA' },
      fields: ['address_components', 'geometry', 'formatted_address'],
      types: ['address'],
    }}
    value={value}
    onBlur={onBlur}
    onPlaceSelected={onPlaceSelected}
    onChange={onChange}
    placeholder={placeholder}
    apiKey={process.env.NEXT_PUBLIC_APP_GOOGLE_MAP_KEY}
    className={className}
    spellCheck="false"
    autoCorrect="false"
  />
);

export default GoogleAutoComplete;

import Image from 'next/image';
import styles from './Directory.module.scss';

const Ilustration = ({
  bgImage,
  textStyle,
  text,
  width,
  height,
  updateChosenRestaurantFilters,
}) => (
  <div
    onClick={() =>
      updateChosenRestaurantFilters(
        'cuisine',
        {
          target: {
            value:
              text == 'Sandwiches'
                ? 'Sandwiches & Burgers'
                : text === 'Mexican'
                ? 'Mexican & Latin American'
                : text,
          },
        },
        true
      )
    }
    className={`flex cursor-pointer ${styles.cuisineCard} relative items-center justify-center bg-background ml-3 my-2 rounded-lg`}>
    <Image src={bgImage} width={width} height={height} alt={`${text}-image`} objectFit="cover" />

    <span
      className={`text-sm ${styles.captionText} font-inter-medium flex-wrap break-words`}
      style={{ position: 'absolute', bottom: 8, ...textStyle }}>
      {text}
    </span>
  </div>
);

export default Ilustration;

import Image from 'next/image';
import { GroupOrderGraphic } from 'assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { StoreContext } from 'pages/_app';

export default function LearnMoreBadge({ setStartGroupOrder }) {
  const store = useContext(StoreContext);

  return (
    <div className="flex flex-col p-4 sm:p-0 md:my-3">
      <div className="flex flex-col pt-[10px] px-[24px] pb-[16px] bg-background rounded-xl">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => store.userStore.setStartOrderShown(true)}
            className="btn-style">
            <FontAwesomeIcon
              style={{ width: 16, height: 16 }}
              icon={faXmark}
              size="2x"
              color="#BBC0C5"
            />
          </button>
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center">
          <div className="hidden sm:flex sm:mr-2">
            <Image
              src={GroupOrderGraphic}
              width={125}
              height={91}
              layout="fixed"
              alt="group-order-graphic"
            />
          </div>

          <div className="flex flex-col">
            <span className="text-primary-black text-md2 font-inter-semibold">
              Introducing Group Orders
            </span>

            <span className="text-md mt-3">
              Perfect for events & meetings: create a group order and share it with invitees or
              colleagues to select and customize their own meals within your set budget
            </span>
          </div>

          <div className="sm:ml-2">
            <button
              onClick={() => setStartGroupOrder(true)}
              className="w-[146px] font-inter-medium text-white btn-style p-4 text-md mt-4 bg-hh-accent-light hover:bg-hh-accent-medium rounded-[32px]">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { StartGroupOrderStep1, StartGroupOrderStep2, StartGroupOrderStep3 } from 'assets/img';
import Image from 'next/image';
import Screen from 'utils/helpers/ScreenSize';
import { StoreContext } from 'pages/_app';

function StartOrderingModal({ visible, setModal }) {
  const store = useContext(StoreContext);
  const screenType = Screen.ScreenType();

  const onPressStartOrdering = () => {
    setModal(false);
    store.userStore.setStartOrderShown(true);
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setModal(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-screen customMd:min-h-full items-center justify-center customMd:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="flex flex-col w-full customMd:w-[90%] md:w-[55%] transform overflow-hidden customMd:rounded-2xl bg-white pb-[40px] text-left align-middle shadow-xl transition-all">
                <div className="flex flex-col bg-background pt-6 px-7">
                  <div className="flex ml-auto">
                    <button type="button" onClick={onPressStartOrdering} className="btn-style">
                      <FontAwesomeIcon
                        style={{ width: 22, height: 22 }}
                        icon={faXmark}
                        size="2x"
                        color="#2D3958"
                      />
                    </button>
                  </div>

                  <div className="flex flex-col customMd:flex-row mt-11 mb-11 items-center customMd:items-start justify-center">
                    <div>
                      <Image
                        src={StartGroupOrderStep1}
                        width={screenType?.isTablet ? 147 : 171}
                        height={screenType?.isTablet ? 163 : 190}
                        layout="fixed"
                        alt="start-step-1"
                      />
                    </div>

                    <div className="mt-6 customMd:mx-10">
                      <Image
                        src={StartGroupOrderStep2}
                        width={screenType?.isTablet ? 168 : 195}
                        height={screenType?.isTablet ? 181 : 210}
                        layout="fixed"
                        alt="start-step-2"
                      />
                    </div>

                    <div>
                      <Image
                        src={StartGroupOrderStep3}
                        width={screenType?.isTablet ? 147 : 171}
                        height={screenType?.isTablet ? 163 : 190}
                        layout="fixed"
                        alt="start-step-3"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center mt-8 pb-20 customMd:pb-0">
                  <span className="text-[32px] font-inter-semibold">Group order</span>

                  <span className="text-md text-center mt-6 w-[40%]">
                    Group orders is a great way to connect your team. Try it today.
                  </span>
                </div>

                <div className="hidden customMd:flex self-center justify-center items-center mt-[37px] w-[40%]">
                  <div className="flex w-full">
                    <button
                      className="btn-purple text-[18px] font-inter-semibold py-3 px-6 w-full"
                      onClick={onPressStartOrdering}>
                      Start Ordering
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        <Transition.Child
          as={'div'}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="customMd:hidden">
            <div className="fixed bg-white w-full py-5 bottom-0 z-[90] justify-center items-center mt-16 border-t-2 border-gray-200">
              <div className="flex justify-center">
                <button className="btn-purple py-3 px-6 ml-3" onClick={onPressStartOrdering}>
                  Start Ordering
                </button>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default observer(StartOrderingModal);
